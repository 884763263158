// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-template-gallery-collection-page-js": () => import("./../src/components/template/Gallery/CollectionPage.js" /* webpackChunkName: "component---src-components-template-gallery-collection-page-js" */),
  "component---src-components-template-product-detail-js": () => import("./../src/components/template/ProductDetail.js" /* webpackChunkName: "component---src-components-template-product-detail-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-popbar-js": () => import("./../src/pages/popbar.js" /* webpackChunkName: "component---src-pages-popbar-js" */),
  "component---src-pages-products-js": () => import("./../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-shop-js": () => import("./../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-social-mission-js": () => import("./../src/pages/social-mission.js" /* webpackChunkName: "component---src-pages-social-mission-js" */),
  "component---src-pages-store-locator-js": () => import("./../src/pages/store-locator.js" /* webpackChunkName: "component---src-pages-store-locator-js" */),
  "component---src-pages-wholesale-js": () => import("./../src/pages/wholesale.js" /* webpackChunkName: "component---src-pages-wholesale-js" */),
  "component---src-pages-win-a-waffle-party-js": () => import("./../src/pages/win-a-waffle-party.js" /* webpackChunkName: "component---src-pages-win-a-waffle-party-js" */)
}

