// const isBrowser = typeof window !== "undefined"

export function getCheckoutId() {
  //  const test = "" || JSON.parse(localStorage?.getItem("checkout_id"))
  // console.log("test:", JSON.parse(localStorage?.getItem("checkout_id")) || "")
  const localId = getLocalStorageItem("checkout_id") || ""
  return localId || ""
}

export function getCart() {
  // const cartInfo = getLocalStorageItem("cart")
  if (typeof window !== "undefined") {
    const cartInfo = getLocalStorageItem("cart") || []
    return cartInfo?.length ? cartInfo : []
  }

  return []
}

export function getBundles() {
  const bundles = getLocalStorageItem("bundles") || {}
  return Object.keys(bundles).length ? bundles : {}
}

function getLocalStorageItem(item) {
  if (typeof window !== "undefined") {
    return JSON.parse(localStorage.getItem(item))
  }
  return null
}
