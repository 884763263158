export function makeBundle(bundler) {
  const {
    state = {},
    completelyUniqueId,
    products,
    main,
    myBundles,
    price,
  } = bundler

  return {
    ...state,
    [completelyUniqueId]: {
      products: products.map(el => ({
        ...el,
        isBundle: completelyUniqueId,
        quantity: 1,
        price: el.variants[0].price,
        image: el.images[0]?.localFile?.childImageSharp?.fluid,
        lineItem: myBundles.find(
          product =>
            product.customAttributes[0].value === completelyUniqueId &&
            product.title === el.title
        ).id,
      })),
      ...main,
      quantity: 1,
      isMainBundle: true,
      price,
      image: main.images[0].localFile.childImageSharp.fluid,
      uniqueId: completelyUniqueId,
    },
  }
}
