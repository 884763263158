export function mergeArr(a, b) {
  const toBeMerged = [...a]
  // console.log("toBeMerged:", toBeMerged)
  const toMerge = [...b]
  // console.log("toMerge:", toMerge)

  const res = []
  // return

  toBeMerged.forEach((product, i) => {
    toMerge.forEach((el, j) => {
      if (product.variantId !== el.variantId) {
        // console.log("here")
        return
      }

      // console.log(el.fromShopify === el.id)

      const newObj = {
        ...product,
        id: el.id,
        size: el.variantTitle.includes("Default") ? null : el.variantTitle,
        otherVariants: product.variants,
      }
      res.push(newObj)
    })
  })
  // console.log("res:", res)

  // return
  return res
}

export function mergeTheState({
  stateCart,
  shopify,
  image,
  otherVariants,
  size,
  price,
  variants,
}) {
  const newState = shopify.map(el => {
    // console.log("el:", el.variantTitle)
    const isInState = stateCart.find(element => element.id === el.id)

    if (isInState) {
      const inState = { ...isInState, ...el }
      // console.log("inState:", inState)

      return inState
    }

    const newItem = {
      ...el,
      image,
      otherVariants,
      size: el.variantTitle.includes("Title") ? null : el.variantTitle,
      price,
      variants,
    }
    // console.log("newItem:", newItem)

    return newItem
  })

  return newState
}

export function beforeMergeArrays(cart, extraData, variantId, quantity) {
  // console.log("variantId:", variantId)
  try {
    let toBeAdded = [...cart]
    const index = toBeAdded.findIndex(el => el.variantId === variantId)
    // console.log("index:", index)

    if (index !== -1) {
      const updating = toBeAdded[index]
      const taDa = {
        ...updating,
        quantity: updating.quantity + quantity,
        ...extraData,
      }
      toBeAdded[index] = { ...taDa }
      // toBeAdded[index].quantity += quantity
    } else {
      toBeAdded = [...cart, { variantId, quantity, ...extraData }]
    }

    // console.log(toBeAdded)

    return toBeAdded
  } catch (error) {
    console.log("error:", error)
  }
}
