import React, {
  useState,
  createContext,
  useContext,
  useReducer,
  useEffect,
} from "react"
import Client from "shopify-buy"
import { getCheckoutId, getCart, getBundles } from "../lib/localStorageStuff"
import addToMailChimp from "gatsby-plugin-mailchimp"

import { oneDay, twentySeconds, oneWeek } from "../lib/timeVariables"
import {
  mergeArr,
  beforeMergeArrays,
  mergeTheState,
} from "../utils/dealWithCheckoutArrays"
import { makeBundle } from "../utils/makeBundle"
import PopUpLoading from "../components/PopUpLoading"

const client = Client.buildClient({
  domain: process.env.GATSBY_SHOPIFY_STORE,
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
})

{
  /* 
  {
    fronzen: {
      infoAboutFronzen: ..., 
      products: []
    }
  }
  Cart
  Bundle (with image)
    Product 1 - Price
    Product 2 - Price -> bundleOption
 */
}

const defaultOpts = {
  state: {
    isLoading: false,
    isCartOpen: false,
    cart: [],
    checkoutId: "",
    total: 0,
    checkout: {},
    message: "",
    bundles: {},
    firstRender: true,
    removing: false,
    error: "",
  },
  toggleCart() {},
  addToCart() {},
  updateSize() {},
  updateCart() {},
  removeFromProduct() {},
  addCoupon() {},
  removeCoupon() {},
  addToCartWithCustomStuff() {},
  addToBundle() {},
  removeFromBundles() {},
}

const initialVals = {
  isLoading: false,
  isCartOpen: false,
  cart: getCart(),
  checkoutId: getCheckoutId(),
  total: 0,
  checkout: {},
  message: "",
  bundles: getBundles(),
  firstRender: true,
  removing: false,
  error: "",
}

function reducer(state, action) {
  switch (action.type) {
    case "TOGGLE_CART": {
      return { ...state, isCartOpen: !state.isCartOpen, error: "" }
    }

    case "TOGGLE_LOADING": {
      return {
        ...state,
        isLoading: true,
        removing: action.removing || state.removing,
        error: "",
      }
    }
    case "NEW_ID": {
      // console.log("getting new ID")
      return {
        ...state,
        checkoutId: action.checkoutId,
        isLoading: false,
        checkout: action.checkout,
        total: action.total,
        cart: action.cart || [],
        bundles: action.bundles || {},
        firstRender: false,
        removing: false,
        error: "",
      }
    }
    case "CLEAR_ALL": {
      // console.log("CLEAR ALL")
      return {
        ...state,
        cart: [],
        total: 0,
        lineItems: [],
        checkoutId: "",
        checkout: {},
        isCartOpen: false,
        isLoading: true,
        message: "",
        bundles: {},
        removing: false,
        error: action.error,
      }
    }
    case "SET_MESSAGE": {
      return { ...state, message: action.message, error: "" }
    }

    case "ADD_BUNDLE": {
      return {
        ...state,
        isLoading: false,
        total: action.total,
        bundles: action.bundles,
        checkout: action.checkout,
        error: "",
        removing: false,
      }
    }

    case "POPULATE_CHECKOUT_INFO": {
      return {
        ...state,
        isLoading: false,
        checkoutId: action.checkoutId ? action.checkoutId : state.checkoutId,
        checkout: action.checkout,
        total: action.total,
        cart: action.cart ? action.cart : state.cart,
        error: "",
      }
    }
    default: {
      return state
    }
  }
}

const StoreContext = createContext(defaultOpts)
const StoreContextProvider = StoreContext.Provider

export function StoreProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialVals)
  // console.log("state:", state)
  const [localError, setLocalError] = useState(false)

  useEffect(() => {
    function beforeClose() {
      localStorage.setItem("last_visit", JSON.stringify(new Date()))
    }

    if (typeof window !== "undefined") {
      const now = new Date()
      const lastVisit = JSON.parse(localStorage.getItem("last_visit")) || 0

      const val = now.getTime() - new Date(lastVisit).getTime()

      const valToCompare = val / 1000 / 60 / 60 // // 1 hour// 24 //  1 day

      if (valToCompare > 1) {
        startFresh()
      } else {
        startCheckout()
      }

      window.addEventListener("beforeunload", beforeClose)
    }

    return () => {
      window.removeEventListener("beforeunload", beforeClose)
    }
  }, [])

  function toggleCart() {
    return dispatch({ type: "TOGGLE_CART" })
  }

  // useEffect(() => {
  //   initCheckout()
  // }, [])

  useEffect(() => {
    function beforeCloseTab() {
      localStorage.setItem("cart", JSON.stringify(state.cart))
      localStorage.setItem("bundles", JSON.stringify(state.bundles))
    }
    if (typeof window !== "undefined") {
      window.addEventListener("beforeunload", beforeCloseTab)
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("beforeunload", beforeCloseTab)
      }
    }
  }, [state.cart, state.bundles])

  function checkoutFech(id) {
    return client.checkout.fetch(id)
  }

  async function startCheckout() {
    dispatch({ type: "TOGGLE_LOADING" })
    try {
      const currentCheckoutId =
        typeof window !== "undefined" ? state.checkoutId : null

      if (!currentCheckoutId) {
        return startFresh()
      }

      const newCheckout = await checkoutFech(currentCheckoutId)

      if (newCheckout?.completedAt) {
        return startFresh()
      }

      return dispatch({
        type: "NEW_ID",
        checkout: newCheckout,
        checkoutId: newCheckout.id,
        total: newCheckout.totalPrice,
        cart: state.cart,
        bundles: state.bundles,
      })
    } catch (error) {
      console.log("error:", error)
      return startFresh()
    }
  }

  async function startFresh(error = "") {
    clearAllLocalStorage()
    dispatch({ type: "CLEAR_ALL", error })
    try {
      const newCheckout = await client.checkout.create()
      if (typeof window !== "undefined") {
        localStorage.setItem("checkout_id", JSON.stringify(newCheckout.id))
      }
      return dispatch({
        type: "NEW_ID",
        checkoutId: newCheckout.id,
        checkout: newCheckout,
        total: newCheckout.totalPrice,
      })
    } catch (error) {
      console.log("error:", error)
    }
  }

  function clearAllLocalStorage() {
    if (typeof window !== "undefined") {
      localStorage.removeItem("checkout_id")
      localStorage.removeItem("cart")
      localStorage.removeItem("last_added")
      localStorage.removeItem("bundles")
    }
  }

  function populateCheckoutInfo({ cart, total, checkout }) {
    // console.log("cart:", cart)
    dispatch({
      type: "POPULATE_CHECKOUT_INFO",
      cart,
      checkout,
      total,
    })
  }

  async function removeBundle(main) {
    dispatch({ type: "TOGGLE_LOADING", removing: true })

    try {
      const products = main.products
      const updater = products.reduce((acc, val) => {
        if (!acc[val.title]) {
          acc = {
            ...acc,
            [val.title]: {
              id: val.lineItem,
              quantity: 1,
              title: val.title,
            },
          }
          return acc
        }

        if (acc[val.title]) {
          acc[val.title].quantity++
        }

        return acc
      }, {})
      const checkouts = []
      for (const product of Object.values(updater)) {
        const checkout = await client.checkout.updateLineItems(
          state.checkoutId,
          [
            {
              id: product.id,
              quantity: 0,
            },
          ]
        )
        // console.log("PRODUCT, ", checkout)

        checkouts.push(checkout)
      }
      const checkout = checkouts[checkouts.length - 1]
      const total = checkout.totalPrice

      const newBundles = { ...state.bundles }
      delete newBundles[main.uniqueId]

      return dispatch({
        type: "ADD_BUNDLE",
        total,
        checkout,
        bundles: newBundles,
      })
    } catch (error) {
      startFresh("Ups something went wrong!")
      return console.log("error:", error)
    }
  }

  async function addToBundle(products, main, price) {
    // console.log(price, "PRICE")
    dispatch({ type: "TOGGLE_LOADING", removing: false })

    const completelyUniqueId = `${main.title}-${Date.now().toString()}`

    const arrOfProducts = products.map(el => ({
      quantity: 1,
      variantId: el.variants[0].shopifyId,
      customAttributes: {
        key: "bundle",
        value: completelyUniqueId,
      },
    }))

    let total = 0

    const itemsAdded = []
    for (let product of arrOfProducts) {
      const add = await addLineItems({ ...product })
      // console.log("add:", add)
      itemsAdded.push(add)
      // total = add.totalPrice
    }
    // console.log("itemsAdded:", itemsAdded)
    // return
    total = Math.max(...itemsAdded.map(el => parseInt(el.totalPrice)))

    const newCheckout = itemsAdded[itemsAdded.length - 1]
    // console.log("newCheckout:", newCheckout)
    const myBundles = newCheckout.lineItems.filter(el =>
      el.customAttributes.find(one => one.key === "bundle")
    )
    // console.log("myBundles:", myBundles)

    const newBundles = makeBundle({
      completelyUniqueId,
      products,
      main,
      price,
      myBundles,
      state: state.bundles,
    })

    toggleCart()
    return dispatch({
      type: "ADD_BUNDLE",
      total,
      bundles: newBundles,
      checkout: newCheckout,
    })

    // const bundlesCopy = {...state.bundles}

    // const theBundles = Object.entries(bundlesCopy)

    // const newVersion = theBundles.map(el => {
    //   if (el[0]) ===
    // })
  }

  async function addToCart(args, cart) {
    // console.log("ADD TO CART", args)
    dispatch({ type: "TOGGLE_LOADING", removing: false })

    // return
    let addItems, newImprovedArr
    if (cart) {
      const data = await ADD_TO_CART(args, cart)

      newImprovedArr = data.newImprovedArr
      addItems = data.addItems
    } else {
      const data = await ADD_TO_CART(args)

      newImprovedArr = data.newImprovedArr
      addItems = data.addItems
    }
    localStorage.setItem("last_added", JSON.stringify(new Date()))

    populateCheckoutInfo({
      cart: newImprovedArr,
      checkout: addItems,
      total: addItems.totalPrice,
    })
    toggleCart()
    return { newImprovedArr }
  }

  async function ADD_TO_CART(
    {
      price,
      image,
      title,
      variantId,
      quantity = 1,
      variants,
      customAttributes = { key: "", value: "" },
    },
    stateCart
  ) {
    const cart = stateCart ? stateCart : state.cart

    try {
      const addItems = await addLineItems({
        variantId,
        quantity,
        customAttributes,
      })
      const nonBundledCart = addItems.lineItems.filter(
        el => !el.customAttributes[0].key
      )

      const letsMerge = nonBundledCart.map(el => ({
        id: el.id,
        title: el.title,
        variantId: el.variant.id,
        variantTitle: el.variant.title,
        productsInfo: variants,
        quantity: el.quantity,
      }))
      // console.log("letsMerge:", letsMerge)

      const extraData2 = {
        price,
        image,
        title,
        otherVariants: variants,
        variants,
      }

      // const test2 = mergeTheState({
      //   stateCart: state.cart,
      //   shopify: letsMerge,
      //   ...extraData2,
      // })
      // console.log("test2:", test2)

      // const newImprovedArr = mergeArr(toBeAdded, letsMerge)
      const newImprovedArr = mergeTheState({
        stateCart: cart,
        shopify: letsMerge,
        ...extraData2,
      })
      // console.log("newImprovedArr:", newImprovedArr)
      return { newImprovedArr, addItems }
    } catch (e) {
      startFresh("Ups something went wrong!")
      console.error(e)
    }
  }

  async function subscribe(email) {
    try {
      const { msg, result } = await addToMailChimp(email)
      if (result !== "success") {
        throw msg
      }

      setMessage(msg)

      return true
    } catch (e) {
      console.log("error message:", { e })
      setMessage(e.split("<a")[0])
      return false
    }
  }

  function setMessage(message) {
    dispatch({ type: "SET_MESSAGE", message })
    setTimeout(() => {
      dispatch({ type: "SET_MESSAGE", message: "" })
    }, 4000)
  }

  async function updateCart(id, quantity) {
    dispatch({ type: "TOGGLE_LOADING" })
    const { checkout, stateCart } = await UPDATE_CART(id, quantity)

    populateCheckoutInfo({
      cart: stateCart,
      checkout,
      total: checkout.totalPrice,
    })

    // dispatch({
    //   type: "POPULATE_CHECKOUT_INFO",
    //   checkout,
    //   total: checkout.totalPrice,
    //   cart: stateCart,
    // })
  }

  async function UPDATE_CART(id, quantity) {
    dispatch({ type: "TOGGLE_LOADING" })
    const stateCart = [...state.cart]
    const index = stateCart.findIndex(el => el.id === id)

    stateCart[index].quantity = quantity
    const update = { ...stateCart[index] }
    delete update.image
    delete update.price
    delete update.title
    delete update.variantId
    try {
      const checkout = await client.checkout.updateLineItems(state.checkoutId, [
        {
          id,
          quantity,
        },
      ])

      return { checkout, stateCart }
      // dispatch({ type: "UPDATE_CART", cart: stateCart })
      // dispatch({
      //   type: "UPDATE_CHECKOUT",
      //   checkout,
      //   total: checkout.totalPrice,
      //   cart: stateCart,
      // })
    } catch (e) {
      console.log("e:", e)
      startFresh("Ups something went wrong!")
    }
  }

  async function removeFromCart(lineItemId) {
    dispatch({ type: "TOGGLE_LOADING", removing: true })
    // console.log("lineItemId:", lineItemId)
    // return
    try {
      const { checkout, removedFromCart } = await REMOVE_FROM_CART(lineItemId)
      // console.log("removedFromCart:", removedFromCart)

      return populateCheckoutInfo({
        cart: removedFromCart,
        checkout,
        total: checkout.totalPrice,
      })
    } catch (error) {
      console.log("error:", error)
      return startFresh("Ups something went wrong!")
    }
  }

  async function REMOVE_FROM_CART(lineItemId) {
    dispatch({ type: "TOGGLE_LOADING", removing: true })
    const removedFromCart = [...state.cart].filter(
      cartItem => cartItem.id !== lineItemId
    )
    // console.log("removedFromCart:", removedFromCart)
    try {
      const checkout = await removeLineItems(lineItemId)
      // console.log("checkout:", checkout)

      return { checkout, removedFromCart }
      // dispatch({
      //   type: "POPULATE_CHECKOUT_INFO",
      //   checkout,
      //   total: checkout.totalPrice,
      //   cart: removedFromCart,
      // })
    } catch (e) {
      console.log("e:", e)
      return startFresh("Ups something went wrong!")
    }
  }

  async function removeLineItems(lineItemId) {
    // console.log("lineItemId:", lineItemId)
    return client.checkout.removeLineItems(state.checkoutId, [lineItemId])
  }

  async function addLineItems({ variantId, quantity, customAttributes }) {
    return client.checkout.addLineItems(state.checkoutId, [
      { variantId, quantity, customAttributes },
    ])
  }

  // function updateSize(removeId, product, size, quantity) {
  async function updateSize(removeId, args) {
    dispatch({ type: "TOGGLE_LOADING" })
    const result = await UPDATE_THE_SIZE(removeId, args)

    return result
  }

  async function UPDATE_THE_SIZE(removeId, args) {
    const addId = args.variants.find(el => el.title === args.size)

    const { removedFromCart } = await REMOVE_FROM_CART(removeId)
    return addId
    try {
      // await removeFromCart(removeId)
      // await addToCart(product, addId, quantity)
      // return { message: "worked" }
    } catch (error) {
      console.log("error:", error)
      return { message: "Failed" }
    }
  }

  async function addCoupon(coupon) {
    dispatch({ type: "TOGGLE_LOADING" })
    const totalWithCoupon = await client.checkout.addDiscount(
      state.checkoutId,
      coupon
    )
    // console.log("totalWithCoupon:", totalWithCoupon)
    // dispatch({
    //   type: "UPDATE_CHECKOUT",
    //   checkout: totalWithCoupon,
    //   total: totalWithCoupon.totalPrice,
    //   cart: state.cart,
    // })
    return updateCheckoutFromCoupons(totalWithCoupon, coupon)
  }

  function updateCheckoutFromCoupons(totalWithCoupon, coupon) {
    if (!coupon) {
      couponUpdate(totalWithCoupon)
      return
    }
    const couponCheck =
      totalWithCoupon?.discountApplications?.[0]?.code === coupon

    if (!couponCheck) {
      couponUpdate(totalWithCoupon)
      return { msg: "This coupon code is not available", applied: false }
    }
    couponUpdate(totalWithCoupon)
    return {
      applied: true,
      msg:
        "New Coupon Applied. The total price of your checkout may have changed",
    }
  }

  function couponUpdate(totalWithCoupon) {
    dispatch({
      type: "POPULATE_CHECKOUT_INFO",
      checkout: totalWithCoupon,
      total: totalWithCoupon.totalPrice,
      cart: state.cart,
    })
  }

  async function removeCoupon(coupon) {
    dispatch({ type: "TOGGLE_LOADING" })

    const totalWithCoupon = await client.checkout.removeDiscount(
      state.checkoutId,
      coupon
    )
    return updateCheckoutFromCoupons(totalWithCoupon)
  }

  async function addToCartWithCustomStuff(
    customAttributes,
    variantId,
    quantity = 1
  ) {
    const lineItemsTAdd = [{ variantId, quantity, customAttributes }]

    const test = await addLineItems({ variantId, customAttributes, quantity })
    return test
  }

  return (
    <StoreContextProvider
      value={{
        ...defaultOpts,
        toggleCart,
        state,
        addToCart,
        subscribe,
        updateCart,
        updateSize,
        removeFromCart,
        addCoupon,
        removeCoupon,
        addToCartWithCustomStuff,
        addToBundle,
        removeBundle,
      }}
    >
      {state.isLoading && !state.firstRender && !state.removing && (
        <PopUpLoading text="Updating your cart" />
      )}
      {state.isLoading && !state.firstRender && state.removing && (
        <PopUpLoading text="Updating your cart" />
      )}
      {state.error && <PopUpLoading error={true} text={state.error} />}
      {children}
    </StoreContextProvider>
  )
}

export function useStore() {
  return useContext(StoreContext)
}
